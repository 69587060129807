@import "~styles/variables.scss";
@import "~styles/_animations";

.searchable-table {
  &__wrapper {
    background-color: $white;

    .ant-table-wrapper .ant-pagination {
      margin-inline-end: 16px;
    }
  }

  &__bar-container {
    display: grid;
    grid-template-columns: 1fr 3fr 190px;
    align-items: center;
    margin-inline-start: 16px;
    gap: 16px;

    &.all-jobs {
      grid-template-columns: 1fr;
    }
  }

  &__loading-spinner {
    animation: loading-spin-animation infinite 1s linear;
  }

  &__component {
    .ant-table-row {
      height: 65px;
    }
  }
}
